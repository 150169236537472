<template>
  <v-container :fluid="$vuetify.breakpoint.lgAndDown">
    <search-form :search="search" @searchAll="searchAll"></search-form>
    <v-row justify="center">
      <v-col xs="12" sm="12" md="12">
        <preloader v-if="this.load==1" :color="loaderColor" :scale="1"></preloader>
        <articles-list v-if="articles != null" :articles-searched="articles"></articles-list>
        <oerefs-list v-if="oerefs != null" :oerefs-searched="oerefs"></oerefs-list>
        <ktyps-list v-if="ktyps != null" :ktyps-searched="ktyps"></ktyps-list>
      </v-col>
    </v-row>
  </v-container>
</template>


<script>
import api from "../../components/external/modules/shared/utils/api";

import Preloader from '../../components/external/modules/shared/views/WLoader.vue';
import ArticlesList from '../article/ArticlesList.vue';
import OerefsList from '../oeref/OerefsList.vue';
import KtypsList from '../ktyp/KtypsList.vue';
import SearchForm from './SearchForm.vue';

import {
  VCol,
  VContainer,
  VRow,
} from "vuetify/lib";

export default {
  components: {
    VCol,
    VContainer,
    VRow,
    
    SearchForm,
    ArticlesList,
    OerefsList,
    KtypsList,
    Preloader,
  },

  data() {
    return {
        articles: null,
        oerefs: null,
        ktyps: null,
        search: this.$route.params.search,
        load: 1,
        loaderColor: "#0D47A1"
    };
  },


  created() {
    this.searchAll(this.$route.params.search);
  },

  computed: {
    storedLang: function () {
      return localStorage.storedLang === undefined
      ? "FR"
      : localStorage.storedLang;
    },
  },

  methods: {
    searchAll(searchParam) {
      if (typeof searchParam !== 'undefined') {
        api
          .post("/tecdocsearchglobal/search", {"kind":"inputtecdocsearch", "searchField": searchParam, "lang": this.storedLang})
          .then((res) => {
            this.load = 0;
            if (res.data.status === 200 && res.data.entity !== null) {
              if (res.data.entity.articles != null) {
                this.articles = res.data.entity.articles;
              }
              else {
                this.articles = [];
              }
              if (res.data.entity.oerefs != null) {
                this.oerefs = res.data.entity.oerefs;
              }
              else {
                this.oerefs = [];
              }
              if (res.data.entity.ktyps != null) {
                this.ktyps = res.data.entity.ktyps;
              }
              else {
                this.ktyps = [];
              }
            } else {
              console.log(new Error("Result status : "+res.data.status));
              console.log(res);
            }
          })
          .catch((err) => {
              console.log(new Error("Catching error : "));
              console.log(err);
          });
      }
    },
  }
};
</script>

<style scoped>

</style>
